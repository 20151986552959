.cookiesText{
    margin-left: 1.5em;
    color: #000
}
.cookiesText li{
    margin-left: 2.5em;
}

a {
    text-decoration: none;
    color: #000;
}
@media screen and (max-width: 720px), (orientation: portrait){
    .error{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 95vh;
        width: 100%;
    }
}
@media screen and (min-width: 720px) and (orientation: landscape){
.error{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
}
.errorBlock{
    width: 70%;
    margin: 2%
}    
}


.errorTitle{
    font-size: x-large;
    margin: 5PX;
}

.errorText{
    margin: 5px;
    font-size: large;
    text-align: justify;
    height: 50%;
}
.errorLink{
    font-size: large;
    margin: 5px;
    font-weight: bold;
}
.pageTitle {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@media screen and (min-width: 720px) and (orientation: landscape) {
    .navbarbase {
        height: 98vh;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        margin-right: 5%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .logo {
        padding-left: 50%;
        width: 50%;
    }

    .pageTitle {
        white-space: pre-wrap;
        text-align: right;
    }



}

@media screen and (max-width: 720px), (orientation: portrait) {
    .navbarMobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 5%;
        margin-left: 0;
    }
    
    .navbarMobileUp {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .navbarMobileDown{
        width: 300px;
    }

    .logo {
        width: 100px;
        height: 100px;
    }

    .menu {
        margin: 10px 10px 10px 100px;
        width: 40px;
        height: 40px;
    }

    .nav-elements {
        margin-right: 10%;
    }

    .pageTitleMobile {
        /* width: 200px; */
        align-items: center;
        font-size: 1.5em;
    }
    .menu-icon{
        margin: 0;
    }
    li{
        width: 100%;
    }
}

a {
    text-decoration: none;
}


a:visited {
    color: unset;
}

ul {
    padding-inline-start: 0;
}

.ulList li {
    margin: 0.5em 0;
    list-style-type: none;

    cursor: pointer;

    font-size: x-large;
    text-align: right;

    color: #cdcdcd;
    box-shadow: inset 0 0 0 0 #336b87;
    /* margin: 0 -.25rem; */
    /* transition: color .3s ease-in-out, box-shadow .3s ease-in-out; */
}

.ulList li:hover {
    color: #000;
}


.ulGrabados {
    margin-bottom: 0.5em;
    font-size: medium;
    text-align: right;
}

.active li {
    color: #000;

}

.desactive li a:visited {
    color: unset;
}
.ulGrabados div{
    margin-bottom: 0.5em;
    color: #cdcdcd;
    /* margin: 0 -.25rem; */
    /* transition: color .3s ease-in-out, box-shadow .3s ease-in-out; */
}


.ulGrabados div:hover{
    color: #000;
  }

.activeGrab div{
    color: #000;
}

.desactiveGrab li a:visited{
    color: unset;
}
.home{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
}

.home video{
    width: 90%;
}
@media screen and (min-width: 720px) and (orientation: landscape) {
    .grabados {
        height: 95vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .grabdosContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 28vh);
        justify-content: center;
        justify-items: center;
    }
.boxGrabados{
    margin: 10px;
}
.boxImg {
    width: 25vh;
}
}

@media screen and (max-width: 720px),
(orientation: portrait) {
    .grabados {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .grabdosContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 45vw);
        justify-content: center;
        justify-items: center;
    }
    .boxImg {
        width: 40vw;
        margin: 5px;
    }
}


.boxText {
    font-size: large;
}
.footer {
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
}
.footerBox{
    height: 30px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}
.footerText{
    font-size: small;
    color: #777;
    margin-right: 0.5em;
}
.footer img {
    width: 30px;
    height: 30px;
}
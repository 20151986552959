@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
:root{
  font-family:'Roboto', Verdana, Geneva, Tahoma, sans-serif;
}
@media screen and (max-width: 720px), (orientation: portrait) {
.App {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
}
@media screen and (min-width: 720px) and (orientation: landscape) {
.App {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
}

.AppNavbar {
  width: 15%;
}

.AppPages {
  width: 70%;
}

/* .active{
  color: red;
} */
}

.footer {
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
}
.footerBox{
    height: 30px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}
.footerText{
    font-size: small;
    color: #777;
    margin-right: 0.5em;
}
.footer img {
    width: 30px;
    height: 30px;
}
.pageTitle {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@media screen and (min-width: 720px) and (orientation: landscape) {
    .navbarbase {
        height: 98vh;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        margin-right: 5%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .logo {
        padding-left: 50%;
        width: 50%;
    }

    .pageTitle {
        white-space: pre-wrap;
        text-align: right;
    }



}

@media screen and (max-width: 720px), (orientation: portrait) {
    .navbarMobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 5%;
        margin-left: 0;
    }
    
    .navbarMobileUp {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .navbarMobileDown{
        width: 300px;
    }

    .logo {
        width: 100px;
        height: 100px;
    }

    .menu {
        margin: 10px 10px 10px 100px;
        width: 40px;
        height: 40px;
    }

    .nav-elements {
        margin-right: 10%;
    }

    .pageTitleMobile {
        /* width: 200px; */
        align-items: center;
        font-size: 1.5em;
    }
    .menu-icon{
        margin: 0;
    }
    li{
        width: 100%;
    }
}

a {
    text-decoration: none;
}


a:visited {
    color: unset;
}

ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.ulList li {
    margin: 0.5em 0;
    list-style-type: none;

    cursor: pointer;

    font-size: x-large;
    text-align: right;

    color: #cdcdcd;
    box-shadow: inset 0 0 0 0 #336b87;
    /* margin: 0 -.25rem; */
    /* transition: color .3s ease-in-out, box-shadow .3s ease-in-out; */
}

.ulList li:hover {
    color: #000;
}


.ulGrabados {
    margin-bottom: 0.5em;
    font-size: medium;
    text-align: right;
}

.active li {
    color: #000;

}

.desactive li a:visited {
    color: unset;
}
.ulGrabados div{
    margin-bottom: 0.5em;
    color: #cdcdcd;
    /* margin: 0 -.25rem; */
    /* transition: color .3s ease-in-out, box-shadow .3s ease-in-out; */
}


.ulGrabados div:hover{
    color: #000;
  }

.activeGrab div{
    color: #000;
}

.desactiveGrab li a:visited{
    color: unset;
}
@media screen and (min-width: 720px) and (orientation: landscape) {
    .grabados {
        height: 95vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .grabdosContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 28vh);
        justify-content: center;
        justify-items: center;
    }
.boxGrabados{
    margin: 10px;
}
.boxImg {
    width: 25vh;
}
}

@media screen and (max-width: 720px),
(orientation: portrait) {
    .grabados {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .grabdosContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 45vw);
        justify-content: center;
        justify-items: center;
    }
    .boxImg {
        width: 40vw;
        margin: 5px;
    }
}


.boxText {
    font-size: large;
}
.detailContainer {
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.detailButton {
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #000;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
}
.closeBlack{
  margin: 0;
}

.detailButton:hover {
  font-weight: bold;
}
.detailInfo {
    margin-left: auto;
    margin-right: auto;
    padding: 0.5em;
  }

.detailsTitle {
  font-size: 2rem;
}
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.95);
    color: #fff;
}

.popup_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* left: 20%;
    right: 20%;
    top: 2vh; */
    height: 100vh;
    
    border-radius: 15px;
    
    margin: 0;
    padding: 10px;
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .popup_inner{
        max-height: 100vh;
    }
    .imgExpand img{
        max-width: 90%;
        margin-left: 5%;
        margin-left: 5%;
    }
    .buttonExpand{
        width: 100vw;
        background-color: rgba(255, 255, 255,0);
        border: none;
        color: #fff;
        text-align: right;
        text-decoration: none;
        display: inline-block;
        font-size: 1rem;
        cursor: pointer
    }
    .details{ 
        color: #000;
        position: absolute;
        width: 90%;
        bottom: 10vh;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}
@media screen and (min-width: 720px) and (orientation: landscape) {
    .imgExpand img{
        max-height: 70vh;
    }
    .detailTop{
        width: 100vw;
        text-align: right;
    }
    .buttonExpand{
        margin: auto 0;
        background-color: rgba(255, 255, 255,0);
        border: none;
        color: #fff;
        padding-right: 55px;
        text-align: right;
        text-decoration: none;
        display: inline-block;
        font-size: 1rem;
        cursor: pointer
    }
    .details{ 
        color: #000;
        position: absolute;
        bottom: 10vh;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}

.close{
    width: 40px;
    height: 40px;
}

.info{
    width: 40px;
    height: 40px;
}

.closeBlack{
    margin:0;
    width: 30px;
    height: 30px;  
}

.footerExpand{
    padding-top: 10px;;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleExpand {
    font-size: xx-large;
    margin-right: 1em;
}

.infoExpand{
    cursor: pointer;
}

.info{
    font-size: xx-small;
}

.infoExpand:hover{
    font-weight: bold;
}


.buttonExpand:hover{
    font-weight: bold;
}

.imgMiniatura {
    width: 150px;
    height: 150px;
    cursor: pointer;
}
@media screen and (max-width: 720px), (orientation: portrait) {
    .page{
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px) ;
        justify-content: center;
        justify-items: center;
     }
     .imgMiniatura {
        width: 280px;
        height: 280px;
        padding: 2px;
    }
    .technique {
        font-size: 2em;
        text-align: center; 
        display: flex;
        align-items: center;
    }
}
@media screen and (min-width: 720px) and (orientation: landscape) {
.page{
    display: grid;
    grid-template-columns: repeat(auto-fill, 210px) ;
    justify-content: center;
    justify-items: center;
 }
 .imgMiniatura {
    width: 200px;
    height: 200px;
    
}
.technique {
    /* font-size: calc(1em + 1vw); */
    font-size: 1vw;
    text-align: center; 
    display: flex;
    align-items: center;
}
}

.pageMiniature {
    margin-top: 5px;
}


@media screen and (max-width: 720px), (orientation: portrait) {
    .About {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .aboutText{
        width: 95%;
        text-align: justify;
    }
    .aboutImage{
        width: 95%;
    }
    
    .aboutImage img{
        width: 100%;
    }

}
@media screen and (min-width: 720px) and (orientation: landscape) {
.About {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 95vh;
}
.aboutText{
    width: 45%
}
.contactText {
    width: 100%;
    text-align: justify;
}

.aboutImage{
    width: 40%;
}

.aboutImage img{
    width: 100%;
}
}
.home{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
}

.home video{
    width: 90%;
}
.cookiesText{
    margin-left: 1.5em;
    color: #000
}
.cookiesText li{
    margin-left: 2.5em;
}

a {
    text-decoration: none;
    color: #000;
}
@media screen and (max-width: 720px), (orientation: portrait){
    .error{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 95vh;
        width: 100%;
    }
}
@media screen and (min-width: 720px) and (orientation: landscape){
.error{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
}
.errorBlock{
    width: 70%;
    margin: 2%
}    
}


.errorTitle{
    font-size: x-large;
    margin: 5PX;
}

.errorText{
    margin: 5px;
    font-size: large;
    text-align: justify;
    height: 50%;
}
.errorLink{
    font-size: large;
    margin: 5px;
    font-weight: bold;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
:root{
  font-family:'Roboto', Verdana, Geneva, Tahoma, sans-serif;
}
@media screen and (max-width: 720px), (orientation: portrait) {
.App {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
}
@media screen and (min-width: 720px) and (orientation: landscape) {
.App {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
}

.AppNavbar {
  width: 15%;
}

.AppPages {
  width: 70%;
}

/* .active{
  color: red;
} */
}

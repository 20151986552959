@media screen and (max-width: 720px), (orientation: portrait) {
    .page{
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px) ;
        justify-content: center;
        justify-items: center;
     }
     .imgMiniatura {
        width: 280px;
        height: 280px;
        padding: 2px;
    }
    .technique {
        font-size: 2em;
        text-align: center; 
        display: flex;
        align-items: center;
    }
}
@media screen and (min-width: 720px) and (orientation: landscape) {
.page{
    display: grid;
    grid-template-columns: repeat(auto-fill, 210px) ;
    justify-content: center;
    justify-items: center;
 }
 .imgMiniatura {
    width: 200px;
    height: 200px;
    
}
.technique {
    /* font-size: calc(1em + 1vw); */
    font-size: 1vw;
    text-align: center; 
    display: flex;
    align-items: center;
}
}

.pageMiniature {
    margin-top: 5px;
}


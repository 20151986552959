.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.95);
    color: #fff;
}

.popup_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* left: 20%;
    right: 20%;
    top: 2vh; */
    height: 100vh;
    
    border-radius: 15px;
    
    margin: 0;
    padding: 10px;
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .popup_inner{
        max-height: 100vh;
    }
    .imgExpand img{
        max-width: 90%;
        margin-left: 5%;
        margin-left: 5%;
    }
    .buttonExpand{
        width: 100vw;
        background-color: rgba(255, 255, 255,0);
        border: none;
        color: #fff;
        text-align: right;
        text-decoration: none;
        display: inline-block;
        font-size: 1rem;
        cursor: pointer
    }
    .details{ 
        color: #000;
        position: absolute;
        width: 90%;
        bottom: 10vh;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media screen and (min-width: 720px) and (orientation: landscape) {
    .imgExpand img{
        max-height: 70vh;
    }
    .detailTop{
        width: 100vw;
        text-align: right;
    }
    .buttonExpand{
        margin: auto 0;
        background-color: rgba(255, 255, 255,0);
        border: none;
        color: #fff;
        padding-right: 55px;
        text-align: right;
        text-decoration: none;
        display: inline-block;
        font-size: 1rem;
        cursor: pointer
    }
    .details{ 
        color: #000;
        position: absolute;
        bottom: 10vh;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.close{
    width: 40px;
    height: 40px;
}

.info{
    width: 40px;
    height: 40px;
}

.closeBlack{
    margin:0;
    width: 30px;
    height: 30px;  
}

.footerExpand{
    padding-top: 10px;;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleExpand {
    font-size: xx-large;
    margin-right: 1em;
}

.infoExpand{
    cursor: pointer;
}

.info{
    font-size: xx-small;
}

.infoExpand:hover{
    font-weight: bold;
}


.buttonExpand:hover{
    font-weight: bold;
}
